<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-03-04 10:57:03
 * @LastEditors: hutian
 * @LastEditTime: 2021-03-10 17:02:35
-->
<template>
  <div class="user-modal">
    <a-modal :title="handle == 'add'?'新增':'编辑'" v-model="visible" width="70%" :confirm-loading="confirmLoading" :footer="null">
      <a-form-model ref="form" :model="rowData" :rules="formRule" :label-col="{span:6}" :wrapper-col="{span:18}">
        <a-row>
          <a-col :md="12">
            <a-form-model-item label="姓名" prop="login.nickName">
              <a-input v-model="rowData.login.nickName"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="12">
            <a-form-model-item label="头像">
              <QiniuUpload type="image" :value.sync="rowData.login.avatar"></QiniuUpload>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :md="12">
            <a-form-model-item label="性别" prop="sex">
              <a-radio-group v-model="rowData.sex">
                <a-radio :value="1">男</a-radio>
                <a-radio :value="2">女</a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
          <a-col :md="12">
            <a-form-model-item label="用户名" prop="login.loginName">
              <a-input v-model="rowData.login.loginName" placeholder="登陆账号"></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :md="12">
            <a-form-model-item label="所属部门" :autoLink="false" ref="test" prop="deptId">
              <a-select placeholder="请选择" v-model="rowData.deptId"  @change="onDepaChange">
                <a-select-option :value="item.id" v-for="(item, index) in deptList" :key="index">{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :md="12">
            <a-form-model-item label="所属角色" :autoLink="false" ref="test" prop="roleIds">
              <a-select placeholder="请选择" v-model="rowData.roleIds"  @change="onRoleChange" mode="multiple">
                <a-select-option :value="item.id" v-for="(item, index) in roleList" :key="index">{{item.name}}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :md="12">
            <a-form-model-item label="联系电话" prop="mobile">
              <a-input v-model="rowData.mobile" placeholder="初始密码为手机号后6位"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="12">
            <a-form-model-item label="邮箱" prop="email">
              <a-input v-model="rowData.email"></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :md="12">
            <a-form-model-item label="出生年月" :autoLink="false" ref="test" prop="birthday">
              <DatePicker :startTime.sync="rowData.birthday"></DatePicker>
            </a-form-model-item>
          </a-col>
          <a-col :md="12">
            <a-form-model-item label="入职日期" :autoLink="false" ref="test" prop="joinDate">
              <DatePicker :startTime.sync="rowData.joinDate"></DatePicker>
            </a-form-model-item>
          </a-col>
          
        </a-row>
        <a-row>
          <a-col :md="12">
            <a-form-model-item label="转正日期" :autoLink="false" ref="test" prop="formalDate">
              <DatePicker :startTime.sync="rowData.formalDate"></DatePicker>
            </a-form-model-item>
          </a-col>
          <a-col :md="12">
            <a-form-model-item label="离职日期" :autoLink="false" ref="test">
              <DatePicker :startTime.sync="rowData.leaveDate"></DatePicker>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :md="12">
            <a-form-model-item label="在职状态" prop="workStatus">
              <a-radio-group v-model="rowData.workStatus">
                <a-radio :value="1">试用期</a-radio>
                <a-radio :value="2">已转正</a-radio>
                <a-radio :value="3">已离职</a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
          <a-col :md="12">
            <a-form-model-item label="状态" prop="login.flagEnable">
              <a-radio-group v-model="rowData.login.flagEnable">
                <a-radio :value="true">启用</a-radio>
                <a-radio :value="false">停用</a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :md="12">
            <a-form-model-item label="学历" prop="education">
              <a-radio-group v-model="rowData.education">
                <a-radio :value="1">高中及以下</a-radio>
                <a-radio :value="2">大专</a-radio>
                <a-radio :value="3">本科</a-radio>
                <a-radio :value="4">研究生</a-radio>
                <a-radio :value="5">博士</a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :md="12">
            <a-form-model-item label="居住地址">
              <PCD
                ref="pcd"
                placeholder="请选择区域"
                :province.sync="rowData.addresses.province"
                :city.sync="rowData.addresses.city"
                :district.sync="rowData.addresses.county"
                :provinceId.sync="rowData.addresses.provinceId"
                :cityId.sync="rowData.addresses.cityId"
                :districtId.sync="rowData.addresses.countyId">
              </PCD>
            </a-form-model-item>
          </a-col>
          <a-col :md="12">
            <a-form-model-item label="详细地址">
              <a-input v-model="rowData.addresses.address"></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :md="12">
            <a-form-model-item label="紧急联系人">
              <a-input v-model="rowData.connectors.name"></a-input>
            </a-form-model-item>
          </a-col>
          <a-col :md="12">
            <a-form-model-item label="紧急联系人电话">
              <a-input v-model="rowData.connectors.mobile"></a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :md="24">
            <a-form-model-item label="备注" :label-col="{span:3}" :wrapper-col="{span:21}">
              <a-textarea v-model="rowData.remark" :auto-size="{ minRows: 3, maxRows: 5 }"></a-textarea>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <div class="footer-bts">
        <a-button type="default" @click="onCancel()">取消</a-button>
        <a-button type="primary" @click="onSubmit()">确定</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
  import moment from 'moment'
  export default {
    data() {
      return {
        visible: false,
        confirmLoading: false,
        rowData: {
          login: {},
          addresses: {},
          connectors: {}
        },
        handle: '',
        // 表单验证
        formRule: {
          'login.nickName': [
            {required: true, message: '请输入姓名', trigger: 'blur'}
          ],
          sex: [
            {required: true, message: '请选择性别', trigger: 'change'}
          ],
          'login.loginName': [
            {required: true, message: '请输入用户名', trigger: 'blur'}
          ],
          deptId: [
            {required: true, message: '请选择所属部门', trigger: 'change'}
          ],
          roleIds: [
            {required: true, message: '请选择所属角色', trigger: 'change'}
          ],
          mobile: [
            {required: true, pattern: new RegExp(/^1[3456789]\d{9}$/), message: '请输入正确的手机号', trigger: 'blur'}
          ],
          email: [
            {pattern: new RegExp(/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/), message: '请输入正确的邮箱地址', trigger: 'blur'}
          ],
          birthday: [
            {required: true, message: '请选择出生年月', trigger: 'change'}
          ],
          joinDate: [
            {required: true, message: '请选择入职日期', trigger: 'change'}
          ],
          formalDate: [
            {required: false, message: '请选择转正日期', trigger: 'change'}
          ],
          workStatus: [
            {required: true, message: '请选择在职状态', trigger: 'change'}
          ],
          education: [
            {required: true, message: '请选择学历', trigger: 'change'}
          ],
        },
        deptList: [],
        roleList: []
      }
    },
    created() {
    },
    methods: {
      isShow(row, name) {
        this.visible = true
        this.handle = name
        this.getDeptList()
        this.getRoleList()
        this.rowData = {
          login: {},
          addresses: {},
          connectors: {}
        }
        if(name == 'add') {
          this.$set(this.rowData.login, 'flagEnable', true)
          this.$set(this.rowData, 'education', 1)
          this.$set(this.rowData, 'workStatus', 1)
          this.$set(this.rowData, 'sex', 1)
          this.rowData.birthday = null
          this.rowData.joinDate = null
          this.rowData.formalDate = null
          this.rowData.leaveDate = null
        }
        if(name == 'edit') {
          this.rowData.id = row.id
          this.axios.get('/api/base/system/manager/selectById/' + row.id).then(res => {
            this.rowData = res.body
            if(res.body.addresses.length != 0) {
              this.$set(this.rowData.addresses, 'provinceId', res.body.addresses[0].provinceId)
              this.$set(this.rowData.addresses, 'province', res.body.addresses[0].province)
              this.$set(this.rowData.addresses, 'cityId', res.body.addresses[0].cityId)
              this.$set(this.rowData.addresses, 'city', res.body.addresses[0].city)
              this.$set(this.rowData.addresses, 'countyId', res.body.addresses[0].countyId)
              this.$set(this.rowData.addresses, 'county', res.body.addresses[0].county)
              this.$set(this.rowData.addresses, 'address', res.body.addresses[0].address)
              this.$nextTick(() => {
                this.$refs.pcd.setPCD()
              })
            }
            if(res.body.connectors.length != 0) {
              this.rowData.connectors = res.body.connectors[0]
            }
          })
        }
      },
      getDeptList() {
        this.axios.get('/api/base/system/department/list/enable').then(res => {
          this.deptList = res.body
        })
      },
      getRoleList() {
        this.axios.get('/api/base/system/role/all').then(res => {
          this.roleList = res.body
        })
      },
      onCancel() {
        this.visible = false
      },
      onSubmit() {
        let addresses = []
        let connectors = []
        addresses.push({
          provinceId: this.rowData.addresses.provinceId,
          province: this.rowData.addresses.province,
          cityId: this.rowData.addresses.cityId,
          city: this.rowData.addresses.city,
          countyId: this.rowData.addresses.countyId,
          county: this.rowData.addresses.county,
          address: this.rowData.addresses.address
        })
        this.rowData.addresses = addresses
        connectors.push({
          name: this.rowData.connectors.name,
          mobile: this.rowData.connectors.mobile
        })
        this.rowData.connectors = connectors
        this.$refs.form.validate(async (valid) => {
          if (!valid) {
            return
          }
          let url = this.handle == 'edit' ? '/api/base/system/manager/edit' : '/api/base/system/manager/add'
          this.axios.post(url, this.rowData).then(res => {
            if(res.code == 200 ) {
              this.$message.success(res.message)
              this.visible = false
              this.$emit('ok')
            }
          })
        })
      },
      onDepaChange(value) {
        this.$refs.test.onFieldChange() 
      },
      onRoleChange(value) {
        this.$refs.test.onFieldChange() 
      },
    }
  }
</script>

<style lang="scss">
  .footer-bts {
    text-align: right;
  }
</style>
